
.asset-dist-tip {
    background: rgba(32,40,49,0.6);
    border: 1px solid rgba(46,60,76,0.6);
    width: fit-content;
    min-width: 160px;
    border-radius: 10px;;
    padding: 9px 12px;
    color: '#fff';
    .header-box {
        display: flex;
        align-items: center;
        direction: row;
        margin-bottom: 5px;
        .header-text {
            color: #fff;
            margin-left: 8px;
            font-size: 12px;
        }
    }

    .row-data {
        display: flex;
        justify-content: space-between;
        direction: row;
        align-items: center;
        & > div {
            font-size: 12px
        }
    }
}